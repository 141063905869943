import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isLanguageSupported } from './helpers'
import LogoBuddies from './components/LogoBuddies'
import LogoBuddiesSpicy from './components/LogoBuddiesSpicy'
import LogoBadIdeas from './components/LogoBadIdeas'

function Page({ type }: any) {
    const { lang = '' } = useParams()
    const { t, i18n } = useTranslation()

    useEffect(() => {
        if (isLanguageSupported(lang)) {
            i18n.changeLanguage(lang)
        }
    }, [])

    return (
        <main className="flex min-h-[100vh] w-[100vw] flex-col items-center bg-[#F4F0EC] font-nunito">
            <a href="/" className="flex flex-col items-center lg:gap-[60px] lg:flex-row">
                <LogoBuddies size="small" />
                <LogoBuddiesSpicy size="small" />
                <LogoBadIdeas size="small" />
            </a>
            <section
                className="p-[20px] w-full lg:w-[60%]"
                dangerouslySetInnerHTML={{ __html: t(`pages.${type}.content`) }}
            ></section>
        </main>
    )
}

export default Page
