import React from 'react'
import { useTranslation } from 'react-i18next'
import {gameBackgroundsColors, gameType} from '../constants'

type PropsTypes = {
    type: keyof typeof gameType
}

function BadgeGooglePlay({ type }: PropsTypes) {
    const { t, i18n } = useTranslation();
    const { language } = i18n;

    let appHref = '';

    switch (type) {
        case gameType.buddies:
            appHref = t('googlePlayBadge.urlBuddies');
            break;
        case gameType.buddiesSpicy:
            appHref = t('googlePlayBadge.urlBuddiesSpicy');
            break;
        case gameType.badIdeas:
            appHref = t('googlePlayBadge.urlBadIdeas');
            break;
    }

    return (
        <a
            href={appHref}
            target="_blank"
            rel="noreferrer"
        >
            <img
                className="h-[45px] lg:h-[75px]"
                src={`/locales/${language}/download-from-google-play.svg`}
                alt={t('googlePlayBadge.alt')}
            />
        </a>
    )
}

export default BadgeGooglePlay
